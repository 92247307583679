<template>
  <router-link
    tag="div"
    class="flex justify-between items-center py-3"
    :to="`/equipments/edit/${item.id}`"
  >
    <img
      v-if="item.thumbnail"
      v-lazy="`/uploads/equipments/${item.thumbnail}`"
      class="w-12 h-12 rounded-xl shadow-xl object-cover flex-shrink-0"
    >
    <div
      v-else
      class="w-12 h-12 rounded-xl shadow-xl bg-gray-800 flex-shrink-0"
    />
    <div class="flex-grow px-4 overflow-hidden">
      <p class="text-main text-sm truncate">
        {{ item.name }}
      </p>
      <p
        class="text-xs"
        :class="item.stock === 0 ? 'text-red-500 font-bold' : 'text-sub'"
      >
        在庫：{{ item.stock }}
      </p>
      <ui-label
        v-if="item.equipment_place"
        class="text-xxs inline-block px-3 py-1"
      >
        {{ item.equipment_place.name }}
      </ui-label>
    </div>
    <button
      class="w-4 text-right flex-shrink-0 focus:outline-none"
      @click.stop="$emit('action', item)"
    >
      <font-awesome-icon
        class="text-sub text-opacity-75 text-lg"
        :icon="['fas', 'caret-square-down']"
      />
    </button>
  </router-link>
</template>

<script>
export default {
  name: 'EquipmentItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
