<template>
  <div v-if="item">
    <img
      v-if="item.thumbnail"
      :src="`/uploads/equipments/${item.thumbnail}`"
      class="rounded-xl w-full h-48 object-cover mb-3 shadow-xl"
    >
    <div
      v-else
      class="rounded-xl w-full py-16 mb-3 text-center bg-gray-900 bg-opacity-50 text-disabled"
    >
      画像未登録
    </div>
    <h2 class="text-main font-bold text-xl mb-1">
      {{ item.name }}
    </h2>
    <ui-label
      v-if="item.equipment_place"
      class="text-xxs inline-block px-3 py-1 mb-3"
    >
      {{ item.equipment_place.name }}
    </ui-label>
    <div
      v-if="item.comment"
      class="text-xs text-sub mb-2"
    >
      {{ item.comment }}
    </div>
    <div class="mt-4 grid grid-cols-3 gap-4">
      <button
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        :disabled="!item.stock"
        :class="{
          'opacity-25': !item.stock
        }"
        @click="decrementStock"
      >
        <p class="text-teal-400">
          <icon-box-open class="h-9 mx-auto" />
        </p>
        <p class="text-teal-100 text-opacity-75 text-sm mt-2">
          使った！
        </p>
      </button>

      <router-link
        :to="`/equipments/edit/${item.id}`"
        tag="button"
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
      >
        <p class="text-yellow-400">
          <icon-clipboard-with-pen class="h-9 mx-auto" />
        </p>
        <p class="text-yellow-100 text-opacity-75 text-sm mt-2">
          編集
        </p>
      </router-link>
      <a
        target="_blank"
        :href="item.purchase_url || undefined"
        class="block text-center py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        :class="{
          'opacity-25': !item.purchase_url
        }"
      >
        <p class="text-pink-400">
          <icon-shop class="h-9 mx-auto" />
        </p>
        <p class="text-pink-100 text-opacity-75 text-sm mt-2">
          購入先
          <font-awesome-icon
            :icon="['fas', 'external-link-square-alt']"
          />
        </p>
      </a>
    </div>
    <ui-button
      class="w-full mt-6"
      color="error-2"
      @click="deleteConfirmDialog = true"
    >
      削除
    </ui-button>
    <ui-button
      class="w-full mt-3"
      color="secondary"
      @click="$emit('cancel')"
    >
      閉じる
    </ui-button>
    <ui-dialog
      v-model="deleteConfirmDialog"
      title="削除確認"
      type="error"
      :description="`${item.name}を削除してもよろしいですか？`"
    >
      <ui-button
        color="error"
        class="mb-2"
        @click="deleteEquipment"
      >
        削除する
      </ui-button>
      <ui-button @click="deleteConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import EquipmentService from '../../services/EquipmentService';

export default {
  name: 'EquipmentAction',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      deleteConfirmDialog: false,
    };
  },
  methods: {
    async decrementStock() {
      await EquipmentService.decrementStock(this.item.id);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '在庫を１つ減らしました！',
      });
      this.$emit('request-update');
    },
    async deleteEquipment() {
      this.deleteConfirmDialog = false;
      await EquipmentService.remove(this.item.id);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '備品を削除しました！',
      });
      this.$emit('request-update');
      this.$emit('cancel');
    },
  },
};
</script>
