<template>
  <div>
    <ui-input
      v-model="searchBox"
      class="mb-4"
      placeholder="検索"
    />
    <div class="px-1 mb-2 border-t border-b border-gray-800 border-opacity-75">
      <div class="flex items-center h-12">
        <p class="text-sub text-xxs w-24 flex-shrink-0">
          タグで絞り込み：
        </p>
        <div class="whitespace-no-wrap overflow-x-auto">
          <button
            v-for="category in categories"
            :key="category.id"
            class="focus:outline-none text-xxs px-3 py-1 rounded-xl ml-1"
            :class="selectedTagId === category.id ? 'text-main bg-indigo-600 shadow-xl' : 'text-sub bg-level-2'"
            @click="selectedTagId = category.id === selectedTagId ? 0 : category.id"
          >
            {{ category.name }}
          </button>
        </div>
      </div>
    </div>
    <ui-transition>
      <div v-if="!isLoading">
        <equipment-item
          v-for="equipment in filteredEquipments"
          :key="equipment.id"
          :item="equipment"
          @action="showAction"
        />
        <!-- <virtual-list
          class="overflow-y-auto h-screen"
          data-key="id"
          :data-sources="equipments"
          :data-component="EquipmentItem"
          :keeps="30"
          :estimated-size="89"
        /> -->
      </div>
    </ui-transition>
    <ui-action v-model="actionVisible">
      <equipment-action
        :item="activeEquipment"
        @request-update="silentFetch"
        @cancel="actionVisible = false"
      />
    </ui-action>
  </div>
</template>

<script>
import EquipmentService from '../services/EquipmentService';
import EquipmentItem from '../components/equipment/EquipmentItem.vue';
import EquipmentAction from '../components/equipment/EquipmentAction.vue';

export default {
  name: 'Equipment',
  components: {
    EquipmentAction,
    EquipmentItem,
  },
  data: () => ({
    isLoading: true,
    equipments: [],
    actionVisible: false,
    activeEquipment: null,
    categories: [],
  }),
  computed: {
    filteredEquipments() {
      return this.equipments.filter((item) => item.name.includes(this.searchBox)
             && (this.selectedTagId > 0 ? item.equipment_category?.id === this.selectedTagId : true));
    },
    searchBox: {
      get() { return this.$store.getters['app/equipmentSearchBox']; },
      set(value) { this.$store.commit('app/equipmentSearchBox', value); },
    },
    selectedTagId: {
      get() { return this.$store.getters['app/equipmentSelectedTagId']; },
      set(value) { this.$store.commit('app/equipmentSelectedTagId', value); },
    },
  },
  watch: {
    actionVisible(value) {
      if (!value) {
        this.activeEquipment = null;
      }
    },
  },
  async created() {
    EquipmentService.getCategories().then((categories) => {
      this.categories = categories;
    });
    await this.fetch();
    this.$store.dispatch('layout/restorePosition');
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      [this.equipments, this.categories] = await Promise.all([
        EquipmentService.get(),
        EquipmentService.getCategories(),
      ]);
      this.isLoading = false;
    },
    async silentFetch() {
      this.equipments = await EquipmentService.get();
    },
    showAction(equipment) {
      this.actionVisible = true;
      this.activeEquipment = equipment;
    },
  },
};
</script>
